<template>
  <div class="container-fluid link-setup-page-wrapper">
    <Header :is-hide-menu="true" :is-hide-user="false" class="text-left" />
    <div class="link-setup-body">
      <ProgressStep :isLink="true" />
      <!--Type Line-->
      <section v-if="checkLoginType('line')" class="setting-container">
        <div class="box">
          <div class="topic">
            <span class="name">
              {{ lbl['account-setup-connect-line-selection-topic'] }}
            </span>
          </div>
          <div class="body">
            <a-radio-group
              v-model="accountType"
              class="wrapper-radio-custom"
              @change="accountTypeOnChange($event)"
            >
              <a-radio
                value="line"
                class="radio-main"
                :class="{ active: accountType == 'line' }"
              >
                <strong>
                  {{
                    lbl['account-setup-connect-line-selection-have-line-topic']
                  }}
                </strong>
                <div class="desc">
                  {{
                    lbl['account-setup-connect-line-selection-have-line-desc']
                  }}
                </div>
              </a-radio>
              <a-radio
                value="no_line"
                class="radio-main"
                :class="{ active: accountType == 'no_line' }"
              >
                <strong>
                  {{
                    lbl[
                      'account-setup-connect-line-selection-not-have-line-topic'
                    ]
                  }}
                </strong>
                <div class="desc">
                  {{
                    lbl[
                      'account-setup-connect-line-selection-not-have-line-desc'
                    ]
                  }}
                </div>
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <!--LINE Connect-->
        <div v-show="accountType == 'line'" class="box">
          <div class="topic">
            <span class="name">{{
              lbl['account-setup-connect-line-input-topic']
            }}</span>
            <span class="desc">{{
              lbl['account-setup-connect-line-input-topic-desc']
            }}</span>
          </div>
          <div class="body">
            <div
              v-for="(item, index) in lineConnectArr"
              :key="item.id + '_container'"
              class="input-section"
            >
              <div class="name">
                {{ item.title }}
              </div>
              <div class="input-container-wrapper">
                <div class="input-container first">
                  <div class="detail-name">
                    <div class="number">{{ index + index + 1 }}</div>
                    <div class="title">
                      {{ lbl['account-setup-connect-line-input-channel-id'] }}
                    </div>
                  </div>
                  <div class="detail-input">
                    <a-input
                      :id="item.id + '_channel_id_input'"
                      v-model="item.channelId"
                      placeholder="Required"
                      @click="clickChannelId(item.id)"
                    />
                  </div>
                </div>
                <div class="input-container second">
                  <div class="detail-name">
                    <div class="number">{{ index + index + 2 }}</div>
                    <div class="title">
                      {{
                        lbl['account-setup-connect-line-input-channel-secret']
                      }}
                    </div>
                  </div>
                  <div class="detail-input">
                    <a-input
                      :id="item.id + '_channel_secret_input'"
                      v-model="item.channelSecret"
                      placeholder="Required"
                      @click="clickChannelSecret(item.id)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="button-secction">
              <a-button
                id="line_channel_connect_connect_button"
                type="primary"
                block
                class="button"
                :class="{
                  'connect-success': showAlertSuccessLine || isLineConnected,
                }"
                :disabled="!fillLineChannel"
                @click="handleConnectAccount()"
              >
                <img
                  v-if="showAlertSuccessLine || isLineConnected"
                  :src="require('@/assets/images/linked.svg')"
                  alt=""
                />
                {{
                  showAlertSuccessLine || isLineConnected
                    ? lbl['line-channel-connect-success-button']
                    : lbl['line-channel-connect-connect-button']
                }}
              </a-button>
            </div>
          </div>
        </div>

        <!--How to connect line-->
        <div
          id="how_to_connect_line_accordion"
          v-show="accountType == 'line'"
          class="box accordion"
        >
          <div
            class="topic accordion-header cursor-pointer"
            :style="{ 'margin-bottom': isShowHowConnect ? '16px' : '0' }"
            @click="toggleHowConnect()"
          >
            <span class="name">
              <img src="@/assets/images/Video.svg" alt="Video Icon" />
              {{ lbl['how-to-connect-line-topic'] }}
            </span>
            <img
              class="arrow"
              :class="{ active: isShowHowConnect }"
              src="@/assets/images/icon-accordion-arrow-down.svg"
              alt=""
            />
          </div>
          <div
            class="body accordion-body"
            :class="{ 'how-connect-accordion-active': isShowHowConnect }"
          >
            <div>
              {{ lbl['you-can'] }}
              <a class="website" :href="linkVideoLine" target="_blank">{{
                lbl['click']
              }}</a>
              {{ lbl['to-watch-the-video-or-picture'] }}
            </div>
            <template>
              <a-skeleton :loading="!isshowpdfHowConnect" />
              <a-skeleton :loading="!isshowpdfHowConnect" />
              <a-skeleton :loading="!isshowpdfHowConnect" />
              <a-skeleton :loading="!isshowpdfHowConnect" />
            </template>
            <pdf
              v-for="i in numPagesHowConnect"
              :key="i"
              :v-if="isshowpdfHowConnect"
              :src="srcHowConnect"
              :page="i"
              style="display: inline-block; width: 100%"
            />
          </div>
        </div>

        <!--How to create line-->
        <div id="how_to_create_line_accordion" class="box accordion">
          <div
            class="topic accordion-header cursor-pointer"
            :style="{ 'margin-bottom': isShowHowCreate ? '16px' : '0' }"
            @click="toggleHowCreate()"
          >
            <span class="name">{{ lbl['how-to-create-line-topic'] }}</span>
            <img
              class="arrow"
              :class="{ active: isShowHowCreate }"
              src="@/assets/images/icon-accordion-arrow-down.svg"
              alt=""
            />
          </div>
          <div
            class="body accordion-body"
            :class="{ 'how-create-accordion-active': isShowHowCreate }"
          >
            <template>
              <a-skeleton :loading="!isshowpdfHowCreate" />
              <a-skeleton :loading="!isshowpdfHowCreate" />
              <a-skeleton :loading="!isshowpdfHowCreate" />
              <a-skeleton :loading="!isshowpdfHowCreate" />
            </template>
            <pdf
              v-for="i in numPagesHowCreate"
              :key="i"
              :v-if="isshowpdfHowCreate"
              :src="srcHowCreate"
              :page="i"
              style="display: inline-block; width: 100%"
            />
          </div>
        </div>
      </section>

      <!--Type Email-->
      <section
        v-if="
          checkLoginType('email') ||
          checkLoginType('facebook') ||
          (checkLoginType('device') && !checkLoginType('line'))
        "
        class="setting-container"
      >
        <div class="box">
          <div class="topic">
            <span class="name"> {{ lbl['link-setup-select-link-from'] }}</span>
          </div>
          <div class="body">
            <GenLinkEmail
              @urlLinkTh="urlLinkTh = $event"
              @urlLinkEn="urlLinkEn = $event"
            />
          </div>
        </div>
      </section>

      <GoTopButtton v-if="checkLoginType('line')" :bottom="205" />

      <div
        v-if="checkLoginType('line')"
        class="helper-btn"
        @click="isHelper = !isHelper"
      >
        <a-icon type="question-circle" />
      </div>
      <HelperBox :is-show="isHelper" @close="isHelper = $event" />

      <!-- Footer Button -->
      <div class="footer-wraper">
        <b-button class="button back-btn" @click="clickBack()">
          {{ lbl['btn_back'] }}
        </b-button>
        <b-button
          variant="warning"
          class="button"
          html-type="submit"
          ref="submit"
          type="submit"
          :disabled="
            checkLoginType('line')
              ? accountType == 'no_line'
                ? false
                : disabledNext
              : false
          "
          @click="clickSubmit()"
        >
          {{ lbl['company-setup-save-button'] }}
        </b-button>
      </div>
    </div>
    <!-- modal confirm connect account-->
    <b-modal
      ref="my-modal-confirm"
      modal-class="modal-Confirm"
      :modalClass="[localeShort]"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="modal-base">
        <div class="row mt-3">
          <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10">
            <div class="modal-base-head">
              <img src="@/assets/images/Clipboard-check.svg" alt="" />
              {{ lbl['line-channel-connect-regenerate-liff-confirm-title'] }}
            </div>
            <div class="modal-base-body mt-4">
              {{ lbl['line-channel-connect-line-confirm-message'] }}
            </div>
            <div class="modal-base-body mt-2 text-danger">
              *{{
                lbl['line-channel-connect-regenerate-liff-confirm-message2']
              }}
            </div>
          </div>
        </div>
        <div class="modal-base-footer mt-4">
          <button class="btn btn-warning ml-2" @click="callApiConnectLine">
            {{ lbl['line-channel-connect-regenerate-liff-confirm-title'] }}
          </button>
          <button class="btn btn-warning ml-2 cancel" @click="hideModal">
            {{ lbl['line-channel-connect-regenerate-liff-cancel-title'] }}
          </button>
        </div>
      </div>
    </b-modal>
    <PreviewModal :obj-preview="accountInfoObj" @confirm="clickConfirmData()" />
    <UserDetailModal
      :isUserDetailModal="isUserDetailModal"
      :toggle="toggleUserDetailModal"
      :UserDetail="UserDetail"
      :copyToClipBoard="copyToClipBoard"
      :brandId="brandId"
      :terminaId="terminalId"
      :title="userTitle"
    />
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import GenLinkEmail from '@/module/Settings/components/GenLinkEmail'
import ProgressStep from '@/module/Settings/components/ProgressStep'
import PreviewModal from '@/module/Settings/components/PreviewModal'
import Locale from '@/helper/locale.js'
import pdf from 'vue-pdf'
import Vue from 'vue'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsSettingsLine from '@/core/Settings/Line/BzbsSettingsLine'
import UserDetailModal from '@/module/Settings/components/UserDetailModal'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import GoTopButtton from '@/components/Layout/GoTopButtton'
import HelperBox from '@/components/Layout/HelperBox'

export default {
  name: 'ConnectSetting2',
  mixins: [Mixin],
  components: {
    Header,
    GenLinkEmail,
    PreviewModal,
    ProgressStep,
    pdf,
    UserDetailModal,
    AlertModal,
    GoTopButtton,
    HelperBox,
  },
  data: function () {
    return {
      localeShort: Locale.getLocaleShort(),
      profile: Account.getCacheProfile(),
      accountType: 'line',
      isShowHowCreate: false,
      isShowHowConnect: false,
      locale: Locale,
      numPagesHowCreate: undefined,
      numPagesHowConnect: undefined,
      isshowpdfHowCreate: false,
      isshowpdfHowConnect: false,
      howCreatePDF: null,
      howConnectPDF: null,
      showAlertSuccessLine: false,
      showAlertErrorLine: false,
      urlWebhook: '',
      titleTooltip: '',
      businessName: '',
      businessType: '',
      storeType: '',
      loginType: '',
      radio: 1,
      isPage: true,
      isCampaign: false,
      connection: {
        page: [],
        campaign: [],
      },
      isHelper: false,
      page: '',
      campaign: '',
      urlLinkTh: '',
      urlLinkEn: '',
      titleTooltipTh: '',
      titleTooltipEn: '',
      isPhilipines: false,
      accountInfoObj: {
        businessName: '',
        businessType: '',
        storeType: '',
        loginType: '',
        logo: '@/assets/images/blank-photo.png',
        branch: 0,
        isLine: null,
        isConnect: null,
      },
      isUserDetailModal: false,
      UserDetail: {},
      userTitle: '',
      brandId: '',
      branchId: null,
      terminalId: '',
      branchList: [],
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      lineConnectArr: [
        {
          id: 'line_message',
          title: '',
          channelId: null,
          channelSecret: null,
        },
        {
          id: 'line_login',
          title: '',
          channelId: null,
          channelSecret: null,
        },
      ],
      isLineConnected: false,
      linkVideoLine: '',
    }
  },
  created() {
    this.googleTagManager(
      'setting_connect_page',
      'setting_connect',
      'view_connect',
      null,
      'on view',
    )
    this.handleFooter(true)
    this.linkVideoLine = Vue.bzbsConfig.businessRule.link.connectLine
    this.init()
  },
  computed: {
    currentLocale() {
      return Locale.getLocaleShort().toUpperCase()
    },
    disabledNext() {
      if (
        this.checkLoginType('email') ||
        this.checkLoginType('facebook') ||
        (this.checkLoginType('device') && !this.checkLoginType('line'))
      ) {
        if (
          this.urlLinkTh != null &&
          this.urlLinkTh != '' &&
          this.urlLinkEn != null &&
          this.urlLinkEn != ''
        ) {
          return false
        } else {
          return true
        }
      } else if (this.checkLoginType('line')) {
        if (this.urlWebhook != null && this.urlWebhook != '') {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    fillLineChannel() {
      if (
        this.lineConnectArr[0].channelId &&
        this.lineConnectArr[0].channelSecret &&
        this.lineConnectArr[1].channelId &&
        this.lineConnectArr[1].channelSecret
      ) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    this.handleFooter(true)
    Promise.all([this.getHowCreate(), this.getHowConnect()]).then(() => {
      var loadingTaskHowCreate = pdf.createLoadingTask(this.howCreatePDF)
      var loadingTaskHowConnect = pdf.createLoadingTask(this.howConnectPDF)
      this.srcHowCreate = loadingTaskHowCreate
      this.srcHowConnect = loadingTaskHowConnect

      this.srcHowCreate.promise.then(pdf => {
        this.isshowpdfHowCreate = true
        this.numPagesHowCreate = pdf.numPages
      })

      this.isshowpdfHowConnect = false
      this.isShowHowConnect = false
      this.srcHowConnect.promise
        .then(pdf => {
          this.numPagesHowConnect = pdf.numPages
          this.isshowpdfHowConnect = true
        })
        .catch(() => {
          this.isShowHowConnect = false
        })
    })
  },
  methods: {
    app_id: function () {
      return Cache.get('CRMUser').AppId
    },
    config: function () {
      return Vue.bzbsConfig
    },
    init() {
      this.handleLoading(true)
      if (Vue.bzbsConfig.businessRule.webFor.PH) this.isPhilipines = true
      this.lineConnectArr = [
        {
          id: 'line_message',
          title:
            this.lbl['account-setup-connect-line-input-line-messege-topic'],
          channelId: null,
          channelSecret: null,
        },
        {
          id: 'line_login',
          title: this.lbl['account-setup-connect-line-input-line-login-topic'],
          channelId: null,
          channelSecret: null,
        },
      ]
      this.terminalId = Vue.bzbsConfig.businessRule.default.terminalId
      this.getCRMPlusProfile()
    },
    handleConnectAccount() {
      this.googleTagManager(
        'setting_connect_page',
        'setting_connect_menu',
        'click_connect_line',
        null,
        'on click',
      )
      this.$refs['my-modal-confirm'].show()
    },
    hideModal() {
      this.$refs['my-modal-confirm'].hide()
    },
    callApiConnectLine() {
      this.handleLoading(true)
      this.showAlertSuccessLine = false
      this.isLineConnected = false
      this.showAlertErrorLine = false
      this.showAlertSuccess = false
      this.showAlertError = false

      let params = {
        lineMessageChannelId: this.lineConnectArr[0].channelId,
        lineMessageSecretKey: this.lineConnectArr[0].channelSecret,
        lineLoginChannelId: this.lineConnectArr[1].channelId,
        lineLoginSecretKey: this.lineConnectArr[1].channelSecret,
      }
      return new Promise(resolve => {
        BzbsSettingsLine.postConnectLine(params)
          .then(res => {
            this.handleLoading(false)
            this.$refs['my-modal-confirm'].hide()
            this.urlWebhook = res.data.webHookUrl
            if (res.status == 200) {
              this.isLineConnected = true
              this.accountInfoObj.isConnect = true
              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                this.lbl['line-channel-connect-success-message'],
                'success',
                true,
              )
            }
            console.log('this.urlWebhook :: ', this.urlWebhook)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            this.showAlertErrorLine = true
            this.accountInfoObj.isConnect = false
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              this.lbl['line-channel-connect-error-message'],
              'error',
              true,
            )
            console.log('postConnectLine error', error)
            this.$refs['my-modal-confirm'].hide()
            resolve(error)
          })
      })
    },
    copyClipboard() {
      var copyText = document.getElementById('WebHookUrl')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltip =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    showTooltip() {
      this.titleTooltip =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    copyToClipBoard(obj, type) {
      if (type) {
        navigator.clipboard.writeText(
          `UserName : ${obj.UserName}\r\n Password : ${obj.Password}\r\n Brand Id : ${this.brandId}\r\n Terminal Id : ${this.terminalId}\r\n Branches : ${obj.Branches}\r\n`,
        )
      } else {
        navigator.clipboard.writeText(
          `UserName : ${obj.UserName}\r\n Brand Id : ${this.brandId}\r\n Terminal Id : ${this.terminalId}\r\n Branches : ${obj.Branches}\r\n`,
        )
      }
    },
    toggleUserDetailModal(type, data, title, pass) {
      console.log('toggleUserDetailModal : ', type, data, title, pass)
      this.googleTagManager(
        'setting_connect_page',
        'setting_connect_menu',
        'click_go_home',
        null,
        'on click',
      )
      this.getCRMPlusProfile().then(res => {
        if (res.AppId) {
          Account.syncApplication(
            res.AppId,
            res.BusinessName,
            res.LogoUrl,
            'CRMPlus',
            res.Email,
          )
            .then(() => {
              this.handleLoading(false)
              if (data) this.UserDetail = data
              this.userTitle = title
              this.isUserDetailModal = type
              if (pass) {
                this.$router.push({ name: 'Dashboard' })
              }
            })
            .catch(error => {
              this.handleLoading(false)
              this.alertModalUpdateSyncAction(
                this.lbl['alert-box-error-sync-header'],
                error.response.data.error.message,
                'error',
                true,
              )
            })
        } else {
          if (data) this.UserDetail = data
          this.userTitle = title
          this.isUserDetailModal = type
          if (pass) {
            this.$router.push({ name: 'Dashboard' })
          }
        }
      })
    },
    clickConfirmData() {
      let user = {
        UserName: this.profile.strMembershipUserName,
        BrandId: this.brandId,
        TerminalId: this.terminalId,
        Branches: this.branchId,
      }
      this.toggleUserDetailModal(
        true,
        user,
        this.lbl['user-permissions-setup-success-title'],
      )
    },
    toggleHowCreate() {
      this.isShowHowCreate = !this.isShowHowCreate
      this.isShowHowConnect = false
    },
    toggleHowConnect() {
      this.isShowHowConnect = !this.isShowHowConnect
      this.isShowHowCreate = false
    },
    getHowCreate() {
      return new Promise(resolve => {
        this.howCreatePDF =
          Vue.bzbsConfig.bzbsBlobUrl +
          `/config/crmplus/settingmanual/howto_line_register_${this.currentLocale}.pdf` +
          `?v=` +
          new Date().getTime()
        resolve(this.howCreatePDF)
      })
    },
    getHowConnect() {
      return new Promise(resolve => {
        this.howConnectPDF =
          Vue.bzbsConfig.bzbsBlobUrl +
          `/config/crmplus/settingmanual/howto_line_connect_${this.currentLocale}.pdf` +
          `?v=` +
          new Date().getTime()
        resolve(this.howConnectPDF)
      })
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genDataProfile(res.data)
            this.branchList = res.data.Stores
            this.branchId = this.branchList.map(item => item.ID)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getCRMPlusProfile error', error)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message,
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
    genDataProfile(data) {
      this.handleLoading(true)
      return new Promise(() => {
        this.imgPreview = data.LogoUrl + '?v=' + new Date().getTime()
        this.businessName = data.BusinessName
        this.businessType = data.BusinessType
        this.loginType = data.LoginType ? data.LoginType.toLowerCase() : null
        this.storeType = data.StoreType
        this.brandId = data.EwalletBrandId
        this.accountInfoObj = {
          businessName: this.businessName,
          businessType: this.businessType,
          loginType: this.loginType,
          storeType: this.storeType,
          logo: data.LogoUrl
            ? data.LogoUrl + '?v=' + new Date().getTime()
            : '@/assets/images/blank-photo.png',
          branch: data.Stores.length,
          isLine: this.checkLoginType('email')
            ? false
            : this.checkLoginType('line')
            ? true
            : false,
          isConnect: data.LineMessageChannelId ? true : false,
        }
        this.isHelper = this.checkLoginType('line') ? true : false
        if (
          this.checkLoginType('facebook') ||
          this.checkLoginType('email') ||
          (this.checkLoginType('device') && !this.checkLoginType('line'))
        ) {
          this.titleTooltipTh =
            this.lbl['line-channel-connect-webhook-tooltip-before-copy']
          this.titleTooltipEn =
            this.lbl['line-channel-connect-webhook-tooltip-before-copy']
        } else {
          if (data.LineMessageChannelId != null) {
            this.isLineConnected = true
            this.lineConnectArr[0].channelId = data.LineMessageChannelId
          }
          if (data.LineMessageSecretKey != null) {
            this.lineConnectArr[0].channelSecret = data.LineMessageSecretKey
          }
          if (data.LineLoginChannelId != null) {
            this.lineConnectArr[1].channelId = data.LineLoginChannelId
          }
          if (data.LineLoginSecretKey != null) {
            this.lineConnectArr[1].channelSecret = data.LineLoginSecretKey
          }
          this.handleLoading(false)
          this.getConnectLine()
        }
      })
    },
    checkLoginType(type) {
      if (this.loginType) {
        return this.loginType.toLowerCase().includes(type)
      } else {
        return false
      }
    },
    getConnectLine() {
      this.handleLoading(true)

      this.showAlertSuccessLine = false
      this.showAlertErrorLine = false
      this.showAlertSuccess = false
      this.showAlertError = false
      let params = {
        lineMessageChannelId: this.lineConnectArr[0].channelId,
        lineMessageSecretKey: this.lineConnectArr[0].channelSecret,
        lineLoginChannelId: this.lineConnectArr[1].channelId,
        lineLoginSecretKey: this.lineConnectArr[1].channelSecret,
      }
      return new Promise(resolve => {
        BzbsSettingsLine.getConnectLine(params)
          .then(res => {
            this.handleLoading(false)
            this.urlWebhook = res.data.webHookUrl
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getConnectLine error', error)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message,
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
    accountTypeOnChange(e) {
      if (e.target.value == 'no_line') {
        this.googleTagManager(
          'setting_connect_page',
          'setting_connect_menu',
          'click_not_have_line_oa',
          null,
          'on click',
        )
        this.isShowHowConnect = false
        this.isShowHowCreate = true
        this.accountInfoObj.isLine = false
      } else {
        this.googleTagManager(
          'setting_connect_page',
          'setting_connect_menu',
          'click_have_line_oa',
          null,
          'on click',
        )
        this.isShowHowConnect = false
        this.isShowHowCreate = false
        this.accountInfoObj.isLine = true
      }
    },
    clickSubmit() {
      this.googleTagManager(
        'setting_connect_page',
        'setting_connect_menu',
        'click_save_connect_setting',
        null,
        'on click',
      )
      this.$bvModal.show('preview_modal')
    },
    clickBack() {
      this.$router.push({ name: 'AccountSetting2' })
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          window.location.reload()
          this.alertModalSetting.isOpenModal = false
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    clickChannelId(id) {
      console.log('clickChannelId : ', id)
      if (id == 'line_message') {
        this.googleTagManager(
          'setting_connect_page',
          'setting_connect_menu',
          'click_line_message_channel_id',
          null,
          'on click',
        )
      } else {
        this.googleTagManager(
          'setting_connect_page',
          'setting_connect_menu',
          'click_line_login_channel_id',
          null,
          'on click',
        )
      }
    },
    clickChannelSecret(id) {
      console.log('clickChannelSecret : ', id)
      if (id == 'line_message') {
        this.googleTagManager(
          'setting_connect_page',
          'setting_connect_menu',
          'click_line_message_channel_secret',
          null,
          'on click',
        )
      } else {
        this.googleTagManager(
          'setting_connect_page',
          'setting_connect_menu',
          'click_line_login_channel_secret',
          null,
          'on click',
        )
      }
    },
    alertModalUpdateSyncAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
        setTimeout(() => {
          this.alertModalSetting.title = ''
          this.alertModalSetting.details = ''
          this.alertModalSetting.modalType = ''
        }, 3500)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
        setTimeout(() => {
          this.alertModalSetting.title = ''
          this.alertModalSetting.details = ''
          this.alertModalSetting.modalType = ''
        }, 3500)
      }
    },
  },
}
</script>

<style lang="scss" scope>
@import '@/style/base/common.scss';
.link-setup-page-wrapper {
  padding-bottom: 130px !important;
  text-align: -webkit-center;

  .text-left {
    text-align: left;
  }

  .link-setup-body {
    width: 90%;
    max-width: $max-width-tablet;
    text-align: left;
    .setting-container {
      font-size: 14px;
      margin-top: 30px;

      .box {
        border-radius: 12px;
        background-color: #fff;
        padding: 30px;
        margin-bottom: 16px;

        .topic {
          display: flex;
          margin-bottom: 16px;
          gap: 8px;
          .name {
            color: $color-grey-42;
            font-weight: bold;
            font-size: 16px;
          }
          .desc {
            color: $text-light-gray;
            font-size: 14px;
          }
        }
        .body {
          .wrapper-radio-custom {
            width: 70%;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 16px;

            .radio-main {
              flex: 1;
              min-width: 100px;
              padding: 16px;
              border-radius: 4px;
              border: solid 1px #ffffff;
              background-color: #fafafa;
              margin: 0;
              white-space: normal;
              color: $color-grey-75;

              .desc {
                margin-top: 8px;
              }
              strong {
                color: $color-grey-42;
                margin-bottom: 10px;
              }
            }
            .active {
              border: solid 1px $primary;
              box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
            }
          }

          .input-section {
            width: 100%;
            color: $color-grey-61;
            margin-bottom: 16px;

            .name {
              font-weight: 500;
              margin-bottom: 8px;
            }
            .input-container-wrapper {
              display: flex;
              flex-flow: wrap;
              column-gap: 60px;
              row-gap: 8px;
              .input-container {
                display: flex;
                flex-flow: wrap;
                gap: 16px;
                .detail-name {
                  display: flex;
                  flex-flow: wrap;
                  font-weight: 500;
                  align-items: center;
                  gap: 8px;
                  .number {
                    width: 32px;
                    height: 32px;
                    color: #ffffff;
                    border-radius: 32px;
                    border: 1px solid $primary;
                    background-color: $primary;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
              .second {
                flex-grow: 1;
                .detail-input {
                  flex-grow: 1;
                  max-width: 350px;
                }
              }
            }
          }
          .button-secction {
            .button:disabled {
              color: #fff !important;
              background-color: #c4c4c4 !important;
              border-color: #c4c4c4 !important;
            }
          }
          .connect-success {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border: 1px solid $color-active;
            background-color: $color-active;
            color: #ffffff;
          }
          .gen-link-option-container {
            .select-link-connect-radio-container {
              margin: 8px auto;
            }
            .selected-link-connect-option {
              .field-item {
                .select-box {
                  width: 50%;
                  min-width: 100px;
                }
              }
            }
          }
          .display-link-container {
            display: flex;
            flex-flow: wrap;
            gap: 24px;

            .thai-link-container,
            .en-link-container {
              flex: 1;

              .page-languages {
                color: $color-grey-42;
                font-weight: 600;
                .flag-languages {
                  width: 40px;
                  height: auto;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
      .accordion {
        .accordion-header {
          display: flex;
          justify-content: space-between;

          .arrow {
            transition: all 0.3s ease-in-out;
          }
          .active {
            transform: rotate(180deg);
            transition: all 0.3s ease-in-out;
          }
        }
        .accordion-body {
          max-height: 0;
          transition: max-height 0.3s ease-out;
          overflow: hidden;
        }
        .how-create-accordion-active,
        .how-connect-accordion-active {
          max-height: unset;
          transition: max-height 0.3s ease-in;
        }
      }
    }
    .footer-wraper {
      width: 100%;
      background-color: #ffffff;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 40px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .button {
        width: 20%;
        min-width: 100px;
        padding: 16px;
        border-radius: 6px;
        color: #ffffff;
        background-color: $primary-hover;
      }
      .btn-warning:disabled {
        color: #fff !important;
        background-color: #c4c4c4 !important;
        border-color: #c4c4c4 !important;
      }
      .back-btn {
        border: solid 1px $text-light-gray;
        background-color: #fff;
        color: $text-light-gray;
      }
    }
    .helper-btn {
      cursor: pointer;
      position: fixed;
      bottom: 144px;
      right: 18px;
      color: #b0b0b0;
      border-radius: 30px;
      background: $color-white-f5;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      display: flex;
      padding: 16px 18px;
      justify-content: center;
      align-items: center;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    margin: 16px auto;
  }
}
</style>

<style lang="scss" scope>
@import '../styles/accountsetup_main.scss';
.modal-body {
  padding: 1.5rem 2rem;

  .modal-base-head {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    .icoTitle {
      width: 44px;
      height: 44px;
      padding: 5px 10px 10px 10px;
      border-radius: 4px;
      background-color: rgba(76, 175, 80, 0.1);
    }
  }

  .modal-base-body {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.15px;
    text-align: left;
    color: #616161;
  }

  .modal-base-footer {
    text-align: end;
  }

  .btn-warning {
    color: #ffffff;
    background-color: #ffc107;
    border-color: #ffc107;

    &:hover {
      color: #ffffff;
      background-color: #ecb100;
      border-color: #ecb100;
    }
    &.cancel {
      color: #ffc107;
      background-color: #fff;
      border-color: #ffc107;
      &:hover {
        color: #ffffff;
        background-color: #ecb100;
        border-color: #ecb100;
      }
    }
  }
}
</style>

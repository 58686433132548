<template>
  <div class="gen-link-container">
    <div class="gen-link-option-container">
      <div class="select-link-connect-radio-container">
        <a-radio-group
          v-model="radio"
          class="wrapper-radio-custom"
          @change="changeRadio"
        >
          <a-radio id="line_channel_connection_page_radio" class="" :value="1">
            {{ lbl['line-channel-connection-page'] }}
          </a-radio>
          <a-radio
            v-if="!isSetupFirstTime"
            id="line_channel_connection_campaign_radio"
            class=""
            :value="2"
          >
            {{ lbl['line-channel-connection-campaign'] }}
          </a-radio>
        </a-radio-group>
      </div>
      <div class="selected-link-connect-option">
        <a-form-item class="field-item">
          <!-- Page -->
          <a-select
            v-if="isPage"
            v-decorator="[
              'connectionPage',
              {
                initialValue: this.page,
                rules: [
                  {
                    required: true,
                    message: '',
                  },
                ],
              },
            ]"
            :default-value="isSetupFirstTime ? connection.page[0].id : null"
            size="large"
            class="select-box"
            :placeholder="this.lbl['miscellaneous-please-select-option']"
            @change="
              e => {
                changePage(e)
              }
            "
          >
            <a-select-option
              v-for="(item, index) in connection.page"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
            {{ connection.page }}
          </a-select>

          <!-- Campaign -->
          <a-select
            v-if="isCampaign"
            v-decorator="[
              'connectionCampaign',
              {
                initialValue: this.campaign,
                rules: [
                  {
                    required: true,
                    message: '',
                  },
                ],
              },
            ]"
            size="large"
            class="select-box"
            :placeholder="this.lbl['miscellaneous-please-select-option']"
            @change="
              e => {
                changeCampaign(e)
              }
            "
          >
            <a-select-option
              v-for="(item, index) in connection.campaign"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
            {{ connection.campaign }}
          </a-select>
        </a-form-item>
      </div>
    </div>
    <div class="display-link-container">
      <div class="thai-link-container">
        <div class="page-languages">
          <img
            v-if="!isPhilipines"
            :src="require('@/assets/images/flag-th.png')"
            alt=""
            class="flag-languages"
          />
          <img
            v-if="isPhilipines"
            :src="require('@/assets/images/flag-ph.png')"
            alt=""
            class="flag-languages"
          />
          {{ lbl['link-setup-link-as-thai'] }}
        </div>
        <div class="input-group text-center mt-3">
          <input
            class="form-control text-center"
            id="LinkUrlTh"
            name="LinkUrlTh"
            readonly="readonly"
            type="text"
            :value="urlLinkTh"
            aria-invalid="false"
          />
          <div class="input-group-append">
            <button
              class="input-group-text copy-web-hook-url"
              data-toggle="tooltip"
              title=""
              data-clipboard-target="#LinkUrlTh"
              @click="copyClipboardTh"
              @mouseover="showTooltipTh"
            >
              <a-tooltip
                slot="suffix"
                id="tooltipClipboard"
                :title="titleTooltipTh"
              >
                <img
                  :src="require('@/assets/images/content_copy.svg')"
                  alt=""
                  class="content-copy"
                />
                {{ lbl['line-channel-connect-copy-button'] }}
              </a-tooltip>
            </button>
          </div>
        </div>
      </div>
      <div class="en-link-container">
        <div class="page-languages">
          <img
            :src="require('@/assets/images/flag-en.png')"
            alt=""
            class="flag-languages"
          />
          {{ lbl['link-setup-link-as-english'] }}
        </div>
        <div class="input-group text-center mt-3">
          <input
            class="form-control text-center"
            id="LinkUrlEn"
            name="LinkUrlEn"
            readonly="readonly"
            type="text"
            :value="urlLinkEn"
            aria-invalid="false"
          />
          <div class="input-group-append">
            <button
              class="input-group-text copy-web-hook-url"
              data-toggle="tooltip"
              title=""
              data-clipboard-target="#LinkUrlEn"
              @click="copyClipboardEn"
              @mouseover="showTooltipEn"
            >
              <a-tooltip
                slot="suffix"
                id="tooltipClipboard"
                :title="titleTooltipEn"
              >
                <img
                  :src="require('@/assets/images/content_copy.svg')"
                  alt=""
                  class="content-copy"
                />
                {{ lbl['line-channel-connect-copy-button'] }}
              </a-tooltip>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import BzbsSettingsLine from '@/core/Settings/Line/BzbsSettingsLine'
import Cache from '@/helper/Cache.js'

export default {
  name: 'GenLinkEmail',
  mixins: [Mixin],
  data() {
    return {
      isPhilipines: false,
      urlLinkTh: '',
      urlLinkEn: '',
      titleTooltipTh: '',
      titleTooltipEn: '',
      radio: 1,
      isPage: true,
      isCampaign: false,
      connection: {
        page: [],
        campaign: [],
      },
      page: '',
      campaign: '',
      appId: '',
      isSetupFirstTime: false,
    }
  },
  created() {
    this.handleFooter(true)
    this.init()
    if (this.$route.name == 'ConnectSetting2') {
      this.isSetupFirstTime = true
      if (this.connection && this.connection.page.length) {
        this.changePage(this.connection.page[0].id)
      }
    }
  },
  watch: {
    urlLinkTh(value) {
      this.$emit('urlLinkTh', value)
    },
    urlLinkEn(value) {
      this.$emit('urlLinkEn', value)
    },
  },
  methods: {
    app_id() {
      return Cache.get('CRMUser').AppId
    },
    config() {
      return Vue.bzbsConfig
    },
    init() {
      if (Vue.bzbsConfig.businessRule.webFor.PH) this.isPhilipines = true
      this.getPageList()
      this.getCampaignList()
    },
    showTooltipTh() {
      this.titleTooltipTh =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    showTooltipEn() {
      this.titleTooltipEn =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    copyClipboardTh() {
      var copyText = document.getElementById('LinkUrlTh')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltipTh =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    copyClipboardEn() {
      var copyText = document.getElementById('LinkUrlEn')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltipEn =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    changeRadio(e) {
      if (e.target.value == 2) {
        this.isPage = false
        this.isCampaign = true
        this.page = ''
        this.campaign = ''
        this.connection.campaign = []
        this.connection.page = []
        this.getCampaignList()
      } else {
        this.isPage = true
        this.isCampaign = false
        this.page = ''
        this.campaign = ''
        this.connection.campaign = []
        this.connection.page = []
        this.getPageList()
      }
    },
    changePage(e) {
      console.log('changePage : ', e)
      this.urlLinkTh =
        this.config().bzbsConsumerUrl +
        '/' +
        e +
        '?app_id=' +
        this.app_id() +
        '&locale=th'

      //Modify by Achira on 202208021444 U-314410
      if (Vue.bzbsConfig.businessRule.webFor.PH)
        this.urlLinkTh =
          this.config().bzbsConsumerUrl +
          '/' +
          e +
          '?app_id=' +
          this.app_id() +
          '&locale=en'

      this.urlLinkEn =
        this.config().bzbsConsumerUrl +
        '/' +
        e +
        '?app_id=' +
        this.app_id() +
        '&locale=en'
    },
    changeCampaign(e) {
      console.log('changeCampaign : ', e)
      this.urlLinkTh =
        this.config().bzbsConsumerUrl +
        '/detail/' +
        e +
        '?app_id=' +
        this.app_id() +
        '&locale=th'

      //Modify by Achira on 202208021444 U-314410
      if (Vue.bzbsConfig.businessRule.webFor.PH)
        this.urlLinkTh =
          this.config().bzbsConsumerUrl +
          '/detail/' +
          e +
          '?app_id=' +
          this.app_id() +
          '&locale=en'

      this.urlLinkEn =
        this.config().bzbsConsumerUrl +
        '/detail/' +
        e +
        '?app_id=' +
        this.app_id() +
        '&locale=en'
    },
    getPageList() {
      let data
      if (this.$route.name == 'ConnectSetting2') {
        data = [
          {
            Id: 'home',
            Name: this.lbl['line-channel-select-home'],
          },
          {
            Id: 'profile',
            Name: this.lbl['line-channel-select-profile'],
          },
        ]
      } else {
        data = [
          {
            Id: 'home',
            Name: this.lbl['line-channel-select-home'],
          },
          {
            Id: 'profile',
            Name: this.lbl['line-channel-select-profile'],
          },
          {
            Id: 'myrewards',
            Name: this.lbl['line-channel-select-myrewards'],
          },
        ]
      }

      const selected = _.map(data, e => {
        return { id: e.Id, name: e.Name }
      })
      this.connection.page = selected
    },
    getCampaignList() {
      return new Promise(resolve => {
        BzbsSettingsLine.getCampaignListUrl()
          .then(res => {
            console.log('getCampaignListUrl', res)
            const selected = _.map(res.data, e => {
              return { id: e.ID, name: e.Name }
            })
            this.connection.campaign = selected
            resolve(res)
          })
          .catch(error => {
            console.log('getCampaignListUrl error', error)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message,
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.gen-link-container {
  .gen-link-option-container {
    .select-link-connect-radio-container {
      margin: 8px auto;
    }
    .selected-link-connect-option {
      .field-item {
        .select-box {
          width: 50%;
          min-width: 100px;
        }
      }
    }
  }
  .display-link-container {
    display: flex;
    flex-flow: wrap;
    gap: 24px;

    .thai-link-container,
    .en-link-container {
      flex: 1;

      .page-languages {
        color: $color-grey-42;
        font-weight: 600;
        .flag-languages {
          width: 40px;
          height: auto;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>

<template>
  <b-modal
    id="preview_modal"
    size="xl"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    :modalClass="[currentLocale]"
  >
    <div class="modal-base">
      <div class="modal-header">
        <div>
          <h3>{{ lbl['preview-setting-account-topic'] }}</h3>
          <span>
            {{ lbl['preview-setting-account-topic-desc'] }}
          </span>
        </div>
        <img
          src="@/assets/images/close-icon.svg"
          alt=""
          class="close-icon"
          @click="$bvModal.hide('preview_modal')"
        />
      </div>
      <div class="modal-desc">
        <div id="1" class="box">
          <div class="number">1</div>
          <div class="account-detail-container">
            <div class="account-detail-information">
              <img :src="objPreview.logo" alt="" class="logo" />
              <div class="info">
                <div class="business-name">
                  <strong>
                    {{ lbl['preview-setting-account-business-name'] }}
                  </strong>
                  <span>{{ objPreview.businessName }}</span>
                </div>
                <div class="business-type">
                  <strong>
                    {{ lbl['preview-setting-account-business-type'] }}
                  </strong>
                  <span>{{ objPreview.businessType }}</span>
                </div>
              </div>
            </div>
            <div class="account-detail-type">
              <div class="campaign-mode">
                <strong>
                  {{ lbl['preview-setting-account-campaign-mode'] }}
                </strong>
                <span>{{
                  objPreview.storeType == 'point'
                    ? lbl['import-mode-point']
                    : lbl['import-mode-stamp']
                }}</span>
              </div>
              <div class="login-type">
                <strong>{{ lbl['preview-setting-account-login-type'] }}</strong>
                <span>
                  {{
                    isIncludes('email') && !isIncludes('facebook')
                      ? lbl['email']
                      : isIncludes('email') && isIncludes('facebook')
                      ? lbl['facebook']
                      : isIncludes('device') && isIncludes('line')
                      ? 'OTP and Line'
                      : isIncludes('device') && !isIncludes('line')
                      ? 'OTP'
                      : lbl['line']
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="noted" class="box">
          <div class="account-detail-container noted">
            <div class="account-detail-noted">
              <img
                src="@/assets/images/arrow-up-blue.svg"
                alt=""
                class="arrow"
              />
              <span>
                {{ lbl['preview-setting-account-noted-1'] }}
                <strong>{{ lbl['preview-setting-account-noted-2'] }}</strong>
                {{ lbl['preview-setting-account-noted-3'] }}
              </span>
              <img
                src="@/assets/images/arrow-up-blue.svg"
                alt=""
                class="arrow"
              />
            </div>
          </div>
        </div>
        <div id="2" class="box">
          <div class="number">2</div>
          <div class="account-detail-container">
            <div class="account-detail-setting">
              <strong>{{ lbl['preview-setting-account-branch'] }}</strong>
              <div class="detail">
                <span>
                  {{
                    lbl['preview-setting-account-line-settup-branch'] +
                    objPreview.branch
                  }}
                </span>
                <img src="@/assets/images/checked.svg" alt="" class="checked" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="isIncludes('line')" id="3" class="box">
          <div class="number">3</div>
          <div class="account-detail-container">
            <div class="account-detail-setting">
              <strong>{{ lbl['preview-setting-account-line-settup'] }}</strong>
              <div class="detail">
                <span>
                  {{
                    !objPreview.isLine
                      ? lbl['preview-setting-account-line-settup-not-connect']
                      : objPreview.isConnect
                      ? lbl['preview-setting-account-line-settup-connect']
                      : lbl['preview-setting-account-line-settup-not-connect']
                  }}
                </span>
                <img
                  :src="
                    !objPreview.isLine
                      ? require(`@/assets/images/Error-circle.svg`)
                      : objPreview.isConnect
                      ? require(`@/assets/images/checked.svg`)
                      : require(`@/assets/images/Error-circle.svg`)
                  "
                  alt=""
                  class="check-icon checked"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-base-footer">
      <button class="btn-close" @click="$bvModal.hide('preview_modal')">
        {{ lbl['confirm-box-cancel-button'] }}
      </button>
      <button @click="clickConfirm()" class="btn-submit">
        {{ lbl['confirm-box-confirm-button'] }}
      </button>
    </div>
  </b-modal>
</template>

<script>
// mixin
import Mixin from '@/mixin/Mixin'
import Locale from '@/helper/locale.js'

export default {
  name: 'PreviewModal',
  mixins: [Mixin],
  data: function () {
    return {
      currentLocale: Locale.getLocaleShort(),
      loginTypeList: [],
    }
  },
  props: {
    objPreview: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  methods: {
    clickConfirm() {
      this.googleTagManager(
        'setting_connect_page',
        'setting_connect_menu',
        'click_cofirm_setting',
        null,
        'on click',
      )
      this.$bvModal.hide('preview_modal')
      this.$emit('confirm')
    },
    isIncludes(type) {
      if (this.objPreview.loginType) {
        return this.objPreview.loginType.includes(type)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

::v-deep .modal-content {
  border-radius: 8px;
  .modal-body {
    font-size: 16px;
    color: $color-grey-42;
    padding: 0;
    .modal-base {
      .modal-header {
        border: 0;
        padding: 28px 28px 0;
        display: flex;
        h3 {
          font-size: 20px;
          font-weight: 500;
        }
        span {
          font-size: 14px;
          color: $text-light-gray;
        }
        .close-icon {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
      .modal-desc {
        padding: 28px;
        background-color: $color-grey-fa;

        .box {
          width: 100%;
          margin-bottom: 16px;
          display: flex;
          .number {
            width: 25px;
            height: 25px;
            margin-top: 16px;
            margin-right: 8px;
            border-radius: 20px;
            border: solid 1px #fff;
            background-color: $primary;
            color: #ffffff;
            font-size: 14px;
            text-align: center;
          }
          .account-detail-container {
            border-radius: 8px;
            border: solid 1px #fff7e6;
            background-color: #fff;
            padding: 16px;
            flex-grow: 1;
            .account-detail-information {
              display: flex;
              justify-content: flex-start;
              flex-flow: wrap;
              gap: 22px;
              .logo {
                width: 80px;
                height: 80px;
              }
              .info {
                width: calc(100% - 102px);
                .business-name,
                .business-type {
                  display: grid;
                  grid-template-columns: 180px auto;
                  margin-bottom: 12px;

                  @media (max-width: 991px) {
                    display: flex;
                    justify-content: flex-start;
                    flex-flow: wrap;
                    gap: 8px;
                  }
                }
              }
            }
            .account-detail-type {
              width: 100%;
              margin-top: 22px;
              .campaign-mode,
              .login-type {
                display: grid;
                grid-template-columns: 180px auto;
                margin-bottom: 12px;

                @media (max-width: 575px) {
                  display: flex;
                  flex-flow: wrap;
                  gap: 8px;
                }
              }
            }
            .account-detail-setting {
              display: flex;
              justify-content: space-between;
              .detail {
                display: flex;
                gap: 10px;
                align-items: center;
              }
            }
          }
          .noted {
            padding: 10px;
            border-radius: 4px;
            border: solid 1px #91d5ff;
            background-color: #e6f7ff;
            color: #1890ff;
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
    .modal-base-footer {
      display: flex;
      justify-content: flex-end;
      padding: 28px;

      button {
        padding: 8px 16px;
      }
      .btn-close {
        background-color: #fff;
        border: solid 1px #d9d9d9;
        border-radius: 6px;
        margin-right: 8px;
      }
      .btn-submit {
        background-color: #ff9800;
        color: #fff;
        border: none;
        border-radius: 6px;
      }
    }
  }
  .check-icon {
    width: 25px;
    height: 25px;
  }
}
</style>

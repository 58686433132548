<template>
  <div class="progress-step">
    <div
      class="progess"
      :class="{
        'progess-active': isCompanyDetail,
        'progress-success': checked,
      }"
      @click="handleClickCompanyDetail"
    >
      <div class="number">
        <div v-if="checked">
          <img src="@/assets/images/check-orange.svg" alt="" />
        </div>
        <div v-else>
          {{ '1' }}
        </div>
      </div>
      <div class="name">{{ lbl['company-detail-tab-connect'] }}</div>
    </div>
    <div class="next-step">{{ '>' }}</div>
    <div
      class="progess"
      :class="{ 'progess-active': isLinkSetup }"
      @click="handleClickLinkSetup"
    >
      <div class="number">2</div>
      <div class="name">
        {{ lbl['link-setup-tab-connect'] }}
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'

export default {
  name: 'ProgressStep',
  mixins: [Mixin],
  data() {
    return {
      isCompanyDetail: false,
      isLinkSetup: false,
      checked: false,
    }
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
      require: false,
    },
    isCompany: {
      type: Boolean,
      default: false,
      require: false,
    },
    isLink: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
  mounted() {
    if (this.$route.query.setting) {
      this.checked = true
    }
    if (this.isCompany) {
      this.isCompanyDetail = true
      this.isLinkSetup = false
    } else if (this.isLink) {
      this.isCompanyDetail = false
      this.isLinkSetup = true
    }
  },
  methods: {
    handleClickCompanyDetail() {
      this.isCompanyDetail = true
      this.isLinkSetup = false
      this.$router.push('/Settings/SelfOnBoard/AccountSetting')
    },
    handleClickLinkSetup() {
      if (this.edit) {
        this.isCompanyDetail = false
        this.isLinkSetup = true
        this.$router.push('/Settings/SelfOnBoard/ConnectSetting')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.progress-step {
  width: 100%;
  justify-content: center;
  display: flex;

  .progess {
    display: flex;
    width: 200px;
    align-items: center;
    place-content: center;
    color: rgba(0, 0, 0, 0.25);
    padding: 12px 0;

    .number {
      width: 32px;
      height: 32px;
      line-height: 2.25;
      border: solid 1px rgba(0, 0, 0, 0.25);
      border-radius: 32px;
      text-align: center;
      margin-right: 8px;
    }
  }
  .progess-active {
    display: flex;
    width: 200px;
    align-items: center;
    place-content: center;
    border-bottom: 3px solid $primary;
    .number {
      width: 32px;
      height: 32px;
      background-color: $primary;
      color: #ffffff;
      border-radius: 32px;
      border: 1px solid $primary;
      text-align: center;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .name {
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .progress-success {
    .number {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      border: 1px solid $primary;
      text-align: center;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .name {
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .next-step {
    align-self: center;
    color: rgba(0, 0, 0, 0.25);
  }
}
</style>

<template>
  <div>
    <div :class="['backDrop', isUserDetailModal ? 'isShow' : '']" />
    <div :class="['UserDetailModal', isUserDetailModal ? 'isShow' : '']">
      <div id="captureDetail" class="box-captrue">
        <div class="modalHeader">
          <img
            :src="require('@/assets/images/gif-check-green.gif')"
            alt=""
            class="header-img"
          />
          <div class="header">{{ title }}</div>
        </div>
        <div class="subText">
          {{ lbl['user-permissions-sub-text'] }}
        </div>
        <div class="mainDetail">
          <div class="body">
            <div class="title d-block">
              <p>{{ lbl['user-permissions-username'] }}:</p>
              <p v-if="title === 'User Created!'">
                {{ lbl['user-permissions-password'] }}:
              </p>
              <p>{{ lbl['user-permissions-brand-id'] }}:</p>
              <p>{{ lbl['user-permissions-terminal'] }}:</p>
              <p>{{ lbl['user-permissions-branch-id'] }}:</p>
            </div>
            <div class="value">
              <p>{{ UserDetail.UserName ? UserDetail.UserName : '-' }}</p>
              <p v-if="title === 'User Created!'">
                {{ UserDetail.Password ? UserDetail.Password : '-' }}
              </p>
              <p>{{ brandId ? brandId : '-' }}</p>
              <p>{{ terminaId ? terminaId : '-' }}</p>
              <p>{{ Branches ? Branches : '-' }}</p>
            </div>
          </div>
          <button
            class="copyBtn"
            @click="copyToClipBoard(UserDetail, title === 'User Created!')"
          >
            <img :src="require('@/assets/images/copy.svg')" alt="" />
          </button>
        </div>
        <hr class="hr-low" />
        <div
          v-for="(item, index) in qrData"
          :key="index"
          class="information-box"
        >
          <div
            :class="{
              'col-12 p-0': index == 'Android',
              'col-12 pl-0 pt-2': index == 'IOS' || index == 'Website',
            }"
          >
            <span> <img :src="item.icon" class="iso-icon" alt="" /> </span
            ><span class="title-platform">{{ item.name }}</span>
          </div>
          <div class="box-qr">
            <div>
              <img class="qr" :src="item.image_url" alt="" />
            </div>
            <div class="text">
              <span class="title">{{ item.title }}</span>
              <div class="br" />
              <span class="detail">{{ item.detail }}</span>
              <div class="br" />
              <div class="box-website">
                <a class="website" :href="item.website" target="_blank">{{
                  item.website
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footerAction">
        <div class="closeBtn-wrapper">
          <button class="closeBtn" @click="toggle(false, null, null, true)">
            {{ lbl['user-permissions-go-back-office'] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { copyImageToClipboard } from 'copy-image-clipboard'
Vue.use(copyImageToClipboard)
import Config from '@/helper/Config.js'
import Mixin from '@/mixin/Mixin'
import * as htmlToImage from 'html-to-image' //ref >> https://vuejscomponent.com/package?name=html-to-image

export default {
  mixins: [Mixin],
  props: {
    UserDetail: {
      type: Object,
    },
    title: {
      type: String,
      default: 'User Created!',
    },
    terminaId: {
      type: String,
    },
    brandId: {
      type: String,
    },
    isUserDetailModal: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Function,
    },
    copyToClipBoard: {
      type: Function,
    },
  },
  data() {
    return {
      copyied: false,
      qrData: {
        Android: {
          icon: require('@/assets/images/android.png'),
          name: 'Android',
          title: 'Scan QR Code to Download Merchant App (APK)',
          detail: 'or open link to download (APK)',
          image_url:
            'https://devstoragebuzzebees.blob.core.windows.net/config/crmplus/qr-code/Android.png',
          website:
            'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
        },
        IOS: {
          icon: require('@/assets/images/ios.png'),
          name: 'IOS',
          title: 'Scan QR Code to Download Merchant App (APK)',
          detail: 'or open link to download (APK)',
          image_url:
            'https://devstoragebuzzebees.blob.core.windows.net/config/crmplus/qr-code/IOS.png',
          website:
            'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
        },
        Website: {
          icon: require('@/assets/images/website.png'),
          name: 'Website',
          title: 'Scan QR Code to Download Merchant App (APK)',
          detail: 'or open link to download (APK)',
          image_url:
            'https://devstoragebuzzebees.blob.core.windows.net/config/crmplus/qr-code/website.png',
          website:
            'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
        },
      },
    }
  },
  watch: {
    lbl(newVal, oldVal) {
      if (newVal != oldVal) {
        this.setLocale()
      }
    },
  },
  computed: {
    Branches() {
      if (!this.UserDetail.Branches) return '-'
      return this.UserDetail.Branches.join(' ')
    },
  },
  created() {
    this.qrData = {
      Android: {
        icon: require('@/assets/images/android.png'),
        name: 'Android',
        title: 'Scan QR Code to Download Merchant App (APK)',
        detail: 'or open link to download (APK)',
        image_url:
          Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/qr-code/Android.png',
        website:
          'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
      },
      IOS: {
        icon: require('@/assets/images/ios.png'),
        name: 'IOS',
        title: 'Scan QR Code to Download Merchant App (APK)',
        detail: 'or open link to download (APK)',
        image_url:
          Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/qr-code/IOS.png',
        website:
          'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
      },
      Website: {
        icon: require('@/assets/images/website.png'),
        name: 'Website',
        title: 'Scan QR Code to Download Merchant App (APK)',
        detail: 'or open link to download (APK)',
        image_url:
          Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/qr-code/website.png',
        website:
          'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
      },
    }
    this.getConfigQrcode()
  },
  methods: {
    setLocale() {
      this.qrData.Android.name = this.lbl['user-permissions-android-qr-name']
      this.qrData.Android.title = this.lbl['user-permissions-android-qr-title']
      this.qrData.Android.detail =
        this.lbl['user-permissions-android-qr-detail']
      //
      this.qrData.IOS.name = this.lbl['user-permissions-ios-qr-name']
      this.qrData.IOS.title = this.lbl['user-permissions-ios-qr-title']
      this.qrData.IOS.detail = this.lbl['user-permissions-ios-qr-detail']
      //
      this.qrData.Website.name = this.lbl['user-permissions-web-qr-name']
      this.qrData.Website.title = this.lbl['user-permissions-web-qr-title']
      this.qrData.Website.detail = this.lbl['user-permissions-web-qr-detail']
    },
    getConfigQrcode() {
      Config.getConfigQrcode().then(res => {
        this.qrData.Android.image_url = res.qrcode[0].image_url
        this.qrData.IOS.image_url = res.qrcode[1].image_url
        this.qrData.Website.image_url = res.qrcode[2].image_url
        //
        this.qrData.Android.website = res.qrcode[0].website
        this.qrData.IOS.website = res.qrcode[1].website
        this.qrData.Website.website = res.qrcode[2].website
      })
    },
    copyImage() {
      var node = document.getElementById('captureDetail')
      htmlToImage.toJpeg(node, { quality: 1 }).then(function (dataUrl) {
        //copy to clipboard
        if (dataUrl) {
          copyImageToClipboard(dataUrl)
        }
      })
      this.copyied = true
      setTimeout(() => {
        this.copyied = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.UserDetailModal {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 90%;
  width: 600px;
  min-height: 530px;
  max-height: 92vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $color-white;
  border: solid 1px $color-grey-e0;
  border-radius: 5px;
  text-align: left;
  z-index: 728;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: $max-width-tablet) {
    max-height: -webkit-fill-available;
  }

  @media (max-width: $max-width-mobile) {
    width: 90%;
    max-height: 92vh;
  }

  &.isShow {
    opacity: 1;
    visibility: visible;
  }

  .box-captrue {
    background: $color-white;
    padding: 16px 16px 0;
  }

  .modalHeader {
    text-align: center;

    .header {
      font-size: 16px;
      color: $color-black-18;
      font-weight: bold;
      margin-left: -8px;
      margin-top: 3px;
    }

    img {
      width: 120px;
      height: 120px;
    }
  }

  .subText {
    color: $color-grey-61;
    margin: 8px 0 16px;
    text-align: center;
  }

  .title-platform {
    color: $color-black-18;
    font-size: 16px;
    margin-left: 8px;
  }

  .hr-low {
    border-color: $color-grey-e4;
  }

  .box-qr {
    display: inline-flex;
    margin-top: 16px;

    .text {
      margin-left: 16px;
      margin-top: -5px;
    }

    .title {
      font-size: 16px;
      color: $color-grey-3f;
      @media (max-width: $max-width-mobile) {
        width: 175px;
      }
    }

    .detail {
      font-size: 14px;
      color: $color-grey-5e;
    }

    .box-website {
      width: 350px;
      word-break: break-word;

      .website {
        color: $primary;
        text-decoration: underline;
        font-size: 14px;
      }
      @media (max-width: $max-width-mobile) {
        width: 175px;
      }
    }

    .br {
      margin: 10px 0;
    }

    .qr {
      width: 80px;
      height: 80px;
    }
  }

  .iso-icon {
    width: 40px;
    height: 40px;
  }

  .information-box {
    padding: 12px;
    margin-bottom: 10px;
    background-color: $background-light-gray-color;
  }
  .mainDetail {
    display: flex;
    justify-content: left;
    align-items: left;
    padding: 24px 30px 16px;
    margin: 0 auto;
    color: $color-grey-61;
    border-radius: 4px;
    border: solid 1px $background-gray-color;
    background-color: $border;

    .body {
      display: flex;
      justify-content: center;
      gap: 12px;
    }
    .copyBtn {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: solid 1px $color-grey-7d7d7d;
      background: $border;
      position: absolute;
      right: 50px;
    }
  }

  .footerAction {
    display: flex;
    padding: 0px 16px 16px;

    .copy {
      margin-top: 32px;
      height: 40px;
      border: none;
      font-size: 16px;
      font-weight: bold;
      color: $color-grey-b5;
      border-radius: 6px;
      border: solid 1px $color-grey-b5;
      background-color: $color-white;
      padding: 8px 16px;
      line-height: 10px;

      img {
        margin-right: 8px;
      }
    }
    .closeBtn-wrapper {
      width: 100%;
      .closeBtn {
        margin-top: 32px;
        width: 100%;
        height: 40px;
        border-radius: 6px;
        border: none;
        font-size: 16px;
        font-weight: bold;
        color: $color-white;
        background-color: $color-orange-2;
      }
    }
  }
}

.backDrop {
  z-index: 4;

  &.isShow {
    opacity: 1;
    visibility: visible;
  }

  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
